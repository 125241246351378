<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

      <el-form-item label="配置日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="操作人">
        <el-select v-model="dataForm.operatorId" clearable placeholder="配置人">
          <el-option v-for="item in sysUser" :key="item.userId" :label="item.realName" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="addOrUpdateHandle(0)" type="primary">新增配置</el-button>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%">

      <el-table-column prop="id" width="200px" header-align="center" align="center" label="任务ID">
      </el-table-column>


      <el-table-column prop="allUidList" header-align="center" align="center" label="配置虚拟角色">
        <template slot-scope="scope">
          {{ getVirtualUsersName(scope.row.allUidList, virtualUser) }}
        </template>
      </el-table-column>


      <el-table-column header-align="center" width="200px" align="center" label="操作人">
        <template slot-scope="scope">
          {{ getCreatedByName(scope.row.createUserId, sysUser) }}
        </template>
      </el-table-column>


      <el-table-column prop="createTime" header-align="center" align="center" label="任务创建时间">
      </el-table-column>

      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">配置编辑
          </el-button>
          <el-button type="text" size="small" style="color: rgba(236, 128, 141, 1)" @click="deleteTask(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./character-add-or-update";
import { getCreatedByName, getVirtualUsersName } from "@/utils/sysUser";
import { pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
  currentConfigId: undefined,
  operatorId: undefined,
  createTimeStart: '',
  createTimeEnd: '',
  createTimeArr: []
}


export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      sysUser: [],
      virtualUser: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      pickerOptions: pickerOptions,
      addOrUpdateVisible: true
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  async created() {
    this.sysUser = await this.$store.dispatch("GetSysUser", {});
    this.virtualUser = await this.$store.dispatch("GetVirtualUser", {});
  },
  methods: {
    getCreatedByName,
    getVirtualUsersName,
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.url = this.$http.adornUrl(
        `/sys/oss/upload?token=${this.$cookie.get('token')}`
      )
      this.$http({
        url: this.$http.adornUrl('/admin/character-config/page'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          createTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          createTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    deleteTask(id) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/character-config/delete/${id}`),
          method: 'delete'
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })

          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
  },
}
</script>
